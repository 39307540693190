import * as React from 'react';

import { Box, Divider, Flex, HStack, Icon, Input, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react';

import { CheckboxFilter } from './FilterPanel/CheckboxFilter';
import { FilterDrawer } from './FilterDrawer';
import { MdFilterList } from 'react-icons/md';

type MobileFilterProps = {
  handleFilters: any,
  minPrice: number,
  maxPrice: number,
  selectedMinPrice: any,
  setSelectedMinPrice: any,
  selectedMaxPrice: any,
  setSelectedMaxPrice: any,
}

export const MobileFilter = (
  { handleFilters, minPrice, maxPrice, selectedMinPrice, setSelectedMinPrice, selectedMaxPrice, setSelectedMaxPrice }: MobileFilterProps
) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handlePriceChange = () => {
    handleFilters([]);
  };

  return (
    <>
      <Flex width="full" justify="space-between" display={{ base: 'flex', md: 'none' }}>
        <HStack
          as="button"
          fontSize="sm"
          type="button"
          px="3"
          py="1"
          onClick={onOpen}
          borderWidth="1px"
          rounded="md"
        >
          <Icon as={MdFilterList} />
          <Text>Filters</Text>
        </HStack>
      </Flex>
      <FilterDrawer isOpen={isOpen} onClose={onClose} >
        <Stack spacing="6" divider={<StackDivider />}>
          <Stack>
            <CheckboxFilter
              handleFilters={handleFilters}
              spacing="3"
              label="Category"
              checked={false}
              isOpen={true}
            />
          </Stack>
          <Box>
            <Box fontWeight="semibold" mb="2">
              Price range
            </Box>
            <HStack spacing="4" mt="4">
              <HStack spacing="4">
                <Text color="gray.500" fontSize="sm">
                  min
                </Text>
                <Input
                  aria-label="Minimum price"
                  type="number"
                  value={selectedMinPrice}
                  onChange={(e) => setSelectedMinPrice(Number(e.target.value))}
                  onBlur={handlePriceChange}
                  placeholder={`Min ${minPrice}`}
                />
              </HStack>
              <Divider width="8" opacity={1} />
              <HStack spacing="4">
                <Text color="gray.500" fontSize="sm">
                  max
                </Text>
                <Input
                  aria-label="Maximum price"
                  type="number"
                  value={selectedMaxPrice}
                  onChange={(e) => setSelectedMaxPrice(Number(e.target.value))}
                  onBlur={handlePriceChange}
                  placeholder={`Max ${maxPrice}`}
                />
              </HStack>
            </HStack>
          </Box>
        </Stack>
      </FilterDrawer>
    </>
  );
};

