import { Button, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { FC, MutableRefObject, useMemo } from "react";

import { generatePaginationArray } from "../utils/shopUtils";

type PaginationType = {
    baseUrl?: string;
    backgroundButtonColor?: string;
    currentPage?: number;
    numberOfPages?: number;
    prevPageHandler: () => void;
    nextPageHandler: () => void;
    inputValue?: number;
    onInputChange?: (value: number) => void;
    handleInputSubmit?: (e: any) => void;
    numberInputRef?: MutableRefObject<any>;
    goToPage: (number: number) => void;
};

const Pagination: FC<PaginationType> = ({
    baseUrl,
    currentPage,
    numberOfPages,
    prevPageHandler,
    nextPageHandler,
    goToPage,
}) => {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const pages = useMemo(() => {
        if (!numberOfPages || numberOfPages === 1) {
            return [{ type: "pageNumber", number: 1 }];
        }

        return generatePaginationArray(numberOfPages, currentPage!);
    }, [numberOfPages, currentPage, isSmallScreen]);

    return (
        <HStack alignItems="center" justifyContent='center' pt="48px">
            <Button
                // leftIcon={<IoIosArrowBack color="#BB5D82" />}
                bg="transparent"
                color="#0079C1"
                fontWeight="600"
                _hover={{ bg: "transparent" }}
                fontSize="14px"
                isDisabled={currentPage! <= 1}
                onClick={prevPageHandler}
                rel="prev"
                maxW={isSmallScreen ? "20px" : "unset"}
                minW={isSmallScreen ? "20px" : "unset"}
            >
                {isSmallScreen ? "" : "Previous"}
            </Button>
            {pages.map((page) => {
                if (page.type === "threeDots") {
                    return <Text key={`dots-${page.number}`}>...</Text>;
                }
                const activePage = page.number === currentPage;

                return (
                    <Button
                        key={`page-${page.number}`} // Added unique key here
                        _hover={{ bg: "unset" }}
                        variant="unstyled"
                        borderRadius="0"
                        borderBottomWidth="2px"
                        bg="transparent"
                        borderBottomColor={activePage ? "#0079C1" : "transparent"}
                        onClick={() => {
                            if (!activePage) goToPage(page.number!);
                        }}
                        padding={0}
                        maxW={isSmallScreen ? "20px" : "40px"}
                        minW={isSmallScreen ? "20px" : "40px"}
                    >
                        <a
                            href={`/${baseUrl}${(page.number as number) > 1 ? `/?page=${page.number}/` : "/"
                                }`}
                        >
                            {page.number}
                        </a>
                    </Button>
                );
            })}
            <Button
                // rightIcon={<IoIosArrowForward color="#BB5D82" />}
                bg="transparent"
                color="#0079C1"
                fontWeight="600"
                _hover={{ bg: "transparent" }}
                fontSize="14px"
                isDisabled={currentPage === numberOfPages}
                onClick={nextPageHandler}
                rel="next"
                maxW={isSmallScreen ? "20px" : "unset"}
                minW={isSmallScreen ? "20px" : "unset"}
            >
                {isSmallScreen ? "" : "Next"}
            </Button>
        </HStack>
    );
};

export default Pagination;
