import * as React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import AddToCart from '../AddToCart';
import useAuth from '../useAuth'

export const SingleProductCard = ({ props }: SingleProductCardProps) => {
  const { loggedIn, user } = useAuth();
  return (
    <>
      <Stack spacing={useBreakpointValue({ base: '4', md: '5' })} minH="500px" justifyContent="space-between">
        <Link href={`/product/${props.slug}`} _hover={{ textDecoration: "none !important" }}>
          <Box position="relative" height="300px" className="image-container">
            <AspectRatio ratio={4 / 3} height="300px" className="aspect-ratio">
              <Image
                draggable="false"
                fallback={<Skeleton />}
                borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
                className="image"
                src={props.image?.sourceUrl}
                //@ts-ignore
                objectFit={"contain !important"}
              />
            </AspectRatio>
          </Box>
          <Stack>
            <Stack spacing="1" pt="20px">
              {props.productTags.nodes?.slice(0, 1).map((brand: any) =>
                <Text color="gray.600" fontSize="sm">{brand.name}</Text>)}

              <Text fontWeight="medium" color="gray.700" _hover={{ textDecoration: "none", color: "cdicyan.500" }}>
                {props.name}
              </Text>

              <Text marginTop="2 !important" _hover={{ textDecoration: "none !important" }}> {props.price} </Text>
            </Stack>
          </Stack>
        </Link>

        <Stack align="center">

          {!loggedIn ?
            (<Link className="chakra-button css-1oloaee" href='/log-in' _hover={{ textDecoration: "none !important" }} display='block' margin="0px auto !important" ><Button colorScheme="cdicyan" variant="solid" size="lg" mt="16px !important" > Sign In To Order </Button></Link>) :
            (<AddToCart productId={props.databaseId} />)
          }
        </Stack>
      </Stack>
    </>
  )
}

type SingleProductCardProps = {
  props: {
    name: String,
    slug: String,
    price: string,
    regularPrice: string,
    databaseId: number,
    image?: {
      sourceUrl?: string
    },
    productTags: {
      nodes?: []
    }
  }
}