import {
  Box,
  Button,
  Checkbox,
  CheckboxGroupProps,
  Collapse,
  Input,
  InputGroup,
  InputRightElement,
  SlideFade,
  Stack,
  StackProps,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'

import { FetchAllCategories } from '../../hooks/fetchAllCategories'
import { FiSearch } from 'react-icons/fi';
import { PopoverIcon } from '../../Header/PopoverIcon';
import React from 'react'
import { useState } from 'react';

type CheckboxFilterProps = Omit<CheckboxGroupProps, 'onChange'> & {
  hideLabel?: boolean
  options?: Array<{ id: number, label: string; value: string; count?: number }>
  label?: string
  onChange?: (value: string[]) => void
  spacing?: StackProps['spacing']
  showSearch?: boolean
  checked: boolean
  changeChecked?: Function
  handleFilters: Function
  isOpen?: boolean;
}

export const CheckboxFilter = (props: CheckboxFilterProps) => {
  const { handleFilters, checked, changeChecked, options, label, hideLabel, spacing = '2', showSearch, ...rest } = props

  const categories = FetchAllCategories()

  const [Checked, setChecked] = useState([])
  const [query, setQuery] = useState("")

  var filteredCategory = categories.filter((cat: any) => cat.name.toLowerCase().includes(query))

  const handleToggle = (value: any) => {
    const currentIndex = Checked.indexOf(value);
    const newChecked = [...Checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    handleFilters(newChecked)
  }

  const { isOpen, onToggle } = useDisclosure()


  return (
    <Stack as="fieldset" spacing={spacing}>
      <Box>
        <Button onClick={onToggle} variant="ghost" size="lg" p="0px" w="100%" justifyContent="space-between" fontWeight="700" rightIcon={<PopoverIcon isOpen={isOpen} />} _hover={{ backgroundColor: "none" }}>
          {label}
        </Button>
      </Box>
      <Collapse in={isOpen} animateOpacity >
        <InputGroup size="md" pb="4">
          <Input
            placeholder="Search..."
            rounded="md"
            focusBorderColor={mode('blue.500', 'blue.200')}
            onChange={(e): any => setQuery(e.target.value)}
          />
          <InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
            <FiSearch />
          </InputRightElement>
        </InputGroup>

        {query === "" ? <>
          {categories.map((option: any) => {
            if (option.count !== null) {
              return (
                <Checkbox
                  pb="10px"
                  w="100%"
                  onChange={() => handleToggle(option.id)}
                  defaultChecked={checked}
                  key={option.id}
                  value={option.name}
                  colorScheme="blue">

                  <span>{option.name}</span>
                  {option.count !== null && (
                    <Box as="span" color="gray.500" fontSize="sm">
                      {' '}
                      ({option.count})
                    </Box>
                  )}
                </Checkbox>
              )
            } else {
              return (<></>)
            }
          })}
        </> : <>  {filteredCategory.length == 0 ? <>Sorry, no results.</> : <>
          {filteredCategory.map((option: any) => {
            if (option.count !== null) {
              return (
                <Checkbox pb="10px" w="100%" onChange={() => handleToggle(option.id)} defaultChecked={checked} key={option.id} value={option.name} colorScheme="blue">
                  <span>{option.name}</span>
                  {option.count !== null && (
                    <Box as="span" color="gray.500" fontSize="sm">
                      {' '}
                      ({option.count})
                    </Box>
                  )}
                </Checkbox>
              )
            } else {
              return (<></>)
            }
          })}
        </>}
        </>}
      </Collapse>
    </Stack>
  )
}